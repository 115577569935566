<template>
  <div class="profileExperimental">
    <div class="profileExperimental__title">
      {{ $t('profileExperimental.title') }}
    </div>

    <div class="profileExperimental__subTitle">
      {{ $t('profileExperimental.featureTogglesTitle') }}
    </div>

    <div class="profileExperimental__featureToggles">
      <div class="profileExperimental__featureToggle">
        <div class="profileExperimental__featureToggleWrapper">
          <checkbox-toggle
            v-model="champ"
            class="profileExperimental__featureToggleToggle"
          />
        </div>

        <div class="profileExperimental__notificationContent">
          <div class="profileExperimental__featureToggleTitle">
            {{ $t('profileExperimental.champTitle') }}
          </div>
        </div>
      </div>
    </div>

    <profile-saved-notification v-if="showSavedNotification" />
  </div>
</template>

<script>
import ProfileSavedNotification from '@/components/profile/ProfileSavedNotification';
import CheckboxToggle from '@/components/CheckboxToggle';

export default {
  components: {
    ProfileSavedNotification,
    CheckboxToggle,
  },

  data() {
    return {
      showSavedNotification: false,
    };
  },

  computed: {
    champ: {
      get() {
        return this.getToggleValue('champ');
      },
      set(value) {
        this.setToggleValue('champ', value);
      },
    },
  },

  methods: {
    setToggleValue(key, value) {
      this.$store.commit('experimental/setToggleValue', {key, value});
      this.showSaveNotification();
    },

    getToggleValue(key) {
      return localStorage.getItem(`experimental_${key}`) === '1';
    },

    showSaveNotification() {
      this.showSavedNotification = true;

      setTimeout(() => {
        this.showSavedNotification = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileExperimental__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profileExperimental__subTitle {
  @include section--bold;
  margin: rem(32px) 0 rem(18px) 0;
  padding: 0 0 rem(4px) 0;
  color: $color-grey--medium;
  border-bottom: 1px solid $color-grey--light;
}

.profileExperimental__body {
  @include small;
  margin: 0 0 rem(24px) 0;
  max-width: 44ch;
}

.profileExperimental__featureToggle {
  display: flex;
  margin: 0 0 rem(28px) 0;

  &:last-child {
    margin: 0;
  }
}

.profileExperimental__featureToggleWrapper {
  margin: 0 rem(16px) 0 0;
}

.profileExperimental__featureToggleTitle {
  @include small;
  margin: 0 0 rem(6px) 0;
}

.profileExperimental__featureToggleBody {
  @include note;
  max-width: 270px;
  color: $color-grey;
}
</style>